<template>
  <div class="top-bar">
    <div class="top-bar-main">
      <div class="alCen">
        <!-- 二维码 -->
        <!-- 图标 -->
        <img :src="company.icon" class="top-bar-icon2" />
      </div>
      <!-- 标签页 -->
      <div class="menu-list" v-clickoutside="closeShow">
        <!-- 一级菜单 -->
        <div
          v-for="(item, index) in menu_list"
          :key="index"
          class="menu-item"
          :class="[{ 'menu-check': menu_index == index }, 'fs16']"
          @click="firstTo(item.title, item.path)"
          @mouseover="getSecondMenus(item.id, index)"
          @touchstart="
            showSecond(index);
            getSecondMenus(item.id, index);
          "
        >
          {{ item.menuName }}

          <!-- 二级菜单 -->
          <div
            class="second-list"
            v-show="
              second_index == index && $store.state.second_menu_list.length != 0
            "
            @mouseleave="closeShow()"
            @mouseover.stop=""
          >
            <div
              v-for="(item2, index2) in $store.state.second_menu_list"
              :key="index2"
              class="second-item"
              @click.stop="toScheme(item2.title, index)"
            >
              {{ item2.menuName }}
            </div>
          </div>
        </div>
      </div>
      <!-- 公司地址 -->
      <div
        class="alCen maLf32"
        style="min-width: fit-content"
        v-if="!show_down_tab"
      >
        <img :src="company.address_icon" class="top-bar-location" />
        <div class="fs14 bold col444">
          {{ company.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "top-bar",
  components: {},
  data() {
    return {
      company: this.$store.state.company,
      menu_list: this.$store.state.menu_list,
      menu_index: 0,
      show_down_tab: false,
      show_software: false,
      show_number: false,
      show_promote: false,
      show_hardware: false,
      second_menu_list: this.$store.state.second_menu_list,
      second_index: null,
      rest_index: 0,
    };
  },
  watch: {
    $route(to, from) {
      this.initActive();
    },
  },

  created() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.initIndex();
        this.initActive();
      });
    }, 500);
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 初始化激活index
    initIndex() {
      this.menu_index = this.$route.meta.index;
    },
    // 监听滚动
    handleScroll(e) {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 44) this.show_down_tab = true;
      else this.show_down_tab = false;
    },
    // 显示二级菜单
    showSecond(i) {
      if (!this.isMobile()) this.closeShow();
      this.second_index = i;
    },
    closeShow() {
      this.second_index = null;
    },
    // 跳转到方案页面
    toScheme(title, index) {
      this.closeShow();
      this.menu_index = index;
      this.$router.replace({
        path: "/scheme",
        query: {
          title,
        },
      });
    },
    firstTo(title, path) {
      if (title == this.$route.query.title) return;

      if (!this.isMobile()) this.closeShow();
      if (title != "") this.toScheme(title);
      else this.$router.push(path);

      this.menu_index = this.$route.meta.index;
    },
    // 获取二级标题
    getSecondMenus(id, index) {
      this.request({
        url: this.$api.getHcWisdomMenus,
        data: {
          id,
        },
      }).then((res) => {
        let data = {
          data: [],
        };
        this.$store.commit("update_second_menu", data);
        if (res.code == 0) {
          this.showSecond(index);
          this.$store.commit("update_second_menu", res);
        }
      });
    },
    // 标题激活
    initActive() {
      this.menu_list.forEach((item, index) => {
        if (item.menuName == this.$route.query.title) this.menu_index = index;
      });
    },
    isMobile() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      )
        return true; // 移动端
      else return false; // PC端
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./top-bar.less");
</style>
