<template>
  <div id="App">
    <!-- 顶部 -->
    <top-bar />
    <router-view />
    <!-- 底部 -->
    <bottom-bar />
  </div>
</template>

<script>
import BottomBar from "./components/bottom-bar/bottom-bar.vue";
import TopBar from "./components/top-bar/top-bar.vue";
export default {
  name: "App",
  components: {
    BottomBar,
    TopBar,
  },
  data() {
    return {};
  },
  watch: {},
  created() {
    this.getWebsiteMeta();
    this.getHcWisdomMenus();
    this.getNumber();
    this.getAddress();
  },
  methods: {
    getHcWisdomMenus() {
      this.request({
        url: this.$api.getHcWisdomMenus,
        data: {
          id: 0,
        },
      }).then((res) => {
        if (res.code == 0) {
          this.$store.commit("update_menu", res);
        }
      });
    },
    // 获取地址
    getAddress() {
      this.request({
        url: this.$api.getContactAddressList,
        data: {},
      }).then((res) => {
        this.$store.commit("update_address", res);
      });
    },
    // 获取手机号
    getNumber() {
      this.request({
        url: this.$api.getContactNumberList,
        data: {},
      }).then((res) => {
        this.$store.commit("update_number", res);
      });
    },
    getWebsiteMeta() {
      this.request({
        url: this.$api.getWebsiteMeta,
        data: {},
      }).then((res) => {
        this.$store.state.keywordStr = res.data.keywordStr;
        this.$store.state.descContent = res.data.descContent;
        this.$store.state.websiteMeta = res.data.meta;
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", this.$store.state.keywordStr);
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", this.$store.state.descContent);
      });
    },
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}
</style>
<style lang="less" scoped>
@import url("./common/css/common.css");

@font-face {
  font-family: regular;
  src: url("./assets/font/PingFang/pingfangsc-regular.otf");
}
.regular {
  font-family: regular;
}
* {
  font-family: regular;
}
</style>
