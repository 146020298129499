import Vue from 'vue'
import Vuex from 'vuex'
// 请求封装
import {
  request,
} from '../network/request.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 域名
    DOMAIN: "https://www.hcwisdom888.com:8881/hcwisdom",
    company: {
      // 公司地址
      address: '广州市天河北路233号中信广场63楼',
      address_icon: require('../assets/image/index/icon/location.png'),
      address_fontColor: 'rgb(216, 54, 51)',
      // 公司总机
      switchboard: '020-38773186/020-38864778',
      // 电话号码
      phone: '13926215882',
      phone_icon: require('../assets/image/index/icon/mobile.png'),
      phone_fontColor: 'rgb(216, 54, 51)',
      phone_blank: '139 2621 5882',
      phone_blank_fontColor: '#000',
      phone_blank_icon: require('../assets/image/index/icon/wechat.jpg'),

      // 座机号码
      telephone: '4006-634-388',
      telephone_icon: require('../assets/image/index/icon/phone.png'),
      telephone_fontColor: 'rgb(216, 54, 51)',
      telephone_none_line: "4006 634 388",
      telephone_none_line_fontColor: "#000",
      telephone_none_line_icon: require('../assets/image/index/icon/phone.jpg'),
      // 座机号码2
      telephone2: '020-38773186',
      telephone2_icon: require('../assets/image/index/icon/telephone.png'),
      telephone2_fontColor: 'rgb(216, 54, 51)',

      email: '1330999883@qq.com',
      // 二维码
      QRcode: require('../assets/image/index/icon/code.png'),
      icon: require('../assets/image/index/icon/hc.png'),
    },
    // 备案号
    internet_content_provider: '粤ICP备17032245号',
    // 菜单列表
    menu_list: [{
      menuName: '首页',
      path: '/'
    }, {
      menuName: '智慧软件'
    }, {
      menuName: '数字乡村'
    }, {
      menuName: '乡村振兴'
    }, {
      menuName: '农村电商'
    }, {
      menuName: '信息化运维'
    }, {
      menuName: '智慧硬件'
    }, {
      menuName: '关于我们',
      path: '/about'
    }],
    // 二级菜单
    second_menu_list: [],

    about: {
      banner: require('../assets/image/about/banner.jpg'),
      img: [
        require('../assets/image/about/1.jpg'),
        require('../assets/image/about/2.jpg'),
        require('../assets/image/about/3.jpg'),
        require('../assets/image/about/4.jpg')
      ]
    },
    websiteMeta: "数字乡村、广州和财网络科技有限公司、数字乡村、乡村振兴、数字农业、智慧农业、智慧乡村、数字乡村试点方案、数字乡村建设方案、乡村振兴信息化、乡村振兴建设方案	",
    keywordStr: '',
    descContent: '',
    api: {
      getDictList: '/api/common/getDictList',//获取字典列表数据，通过字典类型
      getContactNumberList: '/api/hcwisdom/getContactNumberList',//获取号码列表
      getContactAddressList: '/api/hcwisdom/getContactAddressList',//获取地址列表
      getCarouselList: '/api/hcwisdom/getCarouselList',//获取轮播图列表
      getHcWisdomMenus: '/api/hcwisdom/getHcWisdomMenus',//'获取官网菜单'
      getPlamApplicationList: '/api/hcwisdom/getPlamApplicationList', //获取智慧软件平台应用列表
      getNewsList: '/api/hcwisdom/getNewsList',//获取新闻资讯列表
      getNewsDetail: '/api/hcwisdom/getNewsDetail',//获取新闻资讯详情
      getProductDescList: '/api/hcwisdom/getProductDescList',//获取产品介绍列表
      getSchemeByTitle: '/api/hcwisdom/getSchemeByTitle',//获取方案详情通过文章标题
      getWebsiteMeta: '/api/hcwisdom/getWebsiteMeta'//获取官网标题
    },
    bottom_menu: [{
      menuName: '首页',
      path: '/',
      title: ''
    }, {
      menuName: '智慧软件',
      title: '智慧乡村系统'
    }, {
      menuName: '数字乡村',
      title: '乡村振兴试点方案'
    }, {
      menuName: '乡村振兴',
      title: '乡村振兴'
    }, {
      menuName: '农村电商',
      title: '农村电商'
    }, {
      menuName: '信息化运维',
      title: '信息化运维'
    }, {
      menuName: '智慧硬件',
      title: '乡村振兴与智慧农业物联网'
    }, {
      menuName: '关于我们',
      path: '/about',
      title: ''
    }]

  },
  mutations: {
    // 更新手机号码
    update_number(state, res) {
      let list = res.data
      state.company.telephone = list[0].num
      state.company.telephone_icon = list[0].icon
      state.company.telephone_fontColor = list[0].fontColor

      state.company.phone = list[1].num
      state.company.phone_icon = list[1].icon
      state.company.phone_fontColor = list[1].fontColor

      state.company.telephone2 = list[2].num
      state.company.telephone2_icon = list[2].icon
      state.company.telephone2_fontColor = list[2].fontColor



      state.company.telephone_none_line = list[3].num
      state.company.telephone_none_line_icon = list[3].icon
      state.company.telephone_none_line_fontColor = list[3].fontColor

      state.company.phone_blank = list[4].num
      state.company.phone_blank_icon = list[4].icon
      state.company.phone_blank_fontColor = list[4].fontColor



    },
    // 更新公司地址
    update_address(state, res) {
      let list = res.data
      state.company.address = list[0].address
      state.company.address_icon = list[0].icon
    },
    // 更新一级菜单
    update_menu(state, res) {
      res.data.forEach((item, index) => {
        state.menu_list[index]['menuName'] = item.menuName
        state.menu_list[index]['title'] = item.title
         state.menu_list[index]['id'] = item.id
      })
    },
    // 更新二级菜单
    update_second_menu(state, res) {
      state.second_menu_list = res.data
    },
    getWebsiteMeta(state, res) {
      state.getWebsiteMeta = res.data.meta

    }
  },
  actions: {

  },
  modules: {
  }
})
