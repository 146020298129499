<template>
  <div class="bottom-bar colFFF">
    <div class="bottom-bar-main fs14">
      <div class="bottom-bar-width">
        <div class="alCen bottom-bar-menu">
          <div
            v-for="(item, index) in menu_list"
            :key="index"
            class="bottom-bar-menu-item"
            @click="firstTo(item.title, item.path)"
          >
            <div>
              {{ item.menuName }}
            </div>
          </div>
        </div>
        <div class="mTop32 alCen">
          <div>公司地址：{{ company.address }}</div>
          <div style="margin-left: 10vw">
            公司名称：广州和财网络科技有限公司
          </div>
        </div>
        <div class="mTop14">公司总机：{{ company.switchboard }}</div>
        <div class="mTop14 hand alCen" @click="toRecords">
          <img src="./image/gongan.png" class="gongan" />
          <a class="link">{{ $store.state.internet_content_provider }}</a>
        </div>
        <div class="bottom-bar-QRcode">
          <img :src="company.QRcode" />
          <div class="t-a-c fs14 mTop20">扫我更精彩！</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom-bar",
  components: {},
  data() {
    return {
      company: this.$store.state.company,
      menu_list: this.$store.state.bottom_menu,
    };
  },
  watch: {},
  methods: {
    firstTo(title, path) {
      if (title != "") this.toScheme(title);
      else this.$router.push({ path });
    },
    // 跳转到方案页面
    toScheme(title) {
      this.$router.push({
        path: "/scheme",
        query: {
          title,
        },
      });
    },
    toRecords() {
      window.open("https://beian.miit.gov.cn");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./bottom-bar.less");
</style>
