import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// import 'lib-flexible' //屏幕自适应

import 'amfe-flexible';

import './common/js/derective'

// api地址
Vue.prototype.$api = store.state.api
// 请求封装
import {
  request,
  tokenRequest,
} from './network/request'
Vue.prototype.request = request
Vue.prototype.tokenRequest = tokenRequest

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

