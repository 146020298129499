import Vue from "vue";
const verifyLogin = (binding) => {
  return () => {
    if (!getApp().globalData.isLogin()) return;
    binding.value();
  };
};
Vue.directive("login", {
  bind: (el, binding) => {
    el.addEventListener("click", verifyLogin(binding));
  },
  unbind: (el, binding) => {
    el.removeEventListener("click", verifyLogin(binding));
  },
});



Vue.directive('longpress', function (el, binding) {
  let timer = null;
  let start = (e) => {
    // 如果是点击事件，不启动计时器，直接返回
    if (e.type === 'click') {
      return
    }
    if (timer == null) {
      timer = setTimeout(function () {
        binding.value()
      }, 500)
    }
  }
  let cancel = () => {
    if (timer !== null) {
      clearTimeout(timer)
      timer = null
    }
  }

  // 添加事件监听器
  el.addEventListener("mousedown", start);
  el.addEventListener("touchstart", start);

  // 取消计时器
  el.addEventListener("click", cancel);
  el.addEventListener("mouseout", cancel);
  el.addEventListener("touchend", cancel);
  el.addEventListener("touchcancel", cancel);
})



Vue.directive('clickoutside', {
  bind: (el, binding) => {
    const documentHandler = (e) => {
      //判断点击的区域是否是指令所在的元素内部，如果是，就跳出函数，不往下执行。
      if (el.contains(e.target)) {
        return false;
      }
      //判断当前的指令v-clickoutside有没有写表达式
      if (binding.expression) {
        //binding.value()就是用来执行当前上下文methods中指定的函数的
        binding.value(e);
      }
    }
    //用于在unbind钩子函数中移除对document的click事件监听。
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener('click', documentHandler);
  },
  unbind: () => {
    document.removeEventListener('click', el.__vueClickOutside__);
    //如果不移除，当组件或元素销毁时，它仍存在于内存中
    delete el.__vueClickOutside__;
  }
})
