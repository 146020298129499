import axios from 'axios'
import store from '@/store/index.js'

// 不带token请求
export function request(config) {
  const instance = axios.create({
    baseURL: store.state.DOMAIN,
    method: "POST",
  })
  instance.interceptors.request.use(config => {
    return config
  }, err => {
    return err
  })
  instance.interceptors.response.use(res => {
    return res.data
  }, err => {
    return err
  })
  return instance(config)
}
// 带token请求
export function tokenRequest(config) {
  const instance = axios.create({
    baseURL: configs.DOMAIN,
    method: "POST",
  })
  instance.interceptors.request.use(config => {
    console.log(config);
    config.headers.token = sessionStorage.getItem(config.flag)
    return config
  }, err => {
    return err
  })
  instance.interceptors.response.use(res => {
    console.log(res);
    return res.data
  }, err => {
    return err
  })
  return instance(config)
}
