import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index/index.vue'),
    meta: {
      title: store.state.websiteMeta,
      index: 0
    },
  }, {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/about.vue'),
    meta: {
      title: '关于我们',
      index: 7
    },
  }, {
    path: '/scheme',
    name: 'scheme',
    component: () => import('../views/scheme/scheme.vue'),
    meta: {
      title: store.state.websiteMeta,
    },
  }, {
    path: '/article',
    name: 'articles',
    component: () => import('../views/article/article.vue'),
    meta: {
      title: store.state.websiteMeta,
    },
  }, {
    path: '/product',
    name: 'product',
    component: () => import('../views/product/product.vue'),
    meta: {
      title: '产品介绍',
    },
  }, {
    path: '/news',
    name: 'news',
    component: () => import('../views/news/news.vue'),
    meta: {
      title: '新闻动态',
    },
  },
  {
    path: '/h5',
    name: 'h5',
    component: () => import('../views/h5/h5.vue'),
    meta: {
      title: 'h5',
    },
  },

]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.matched[0]?.meta?.title;
  document
    .querySelector('meta[name="keywords"]')
    .setAttribute("content", store.state.keywordStr);
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", store.state.descContent);
  next()
})




router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  next;
});

export default router
